@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --clap-color-orange: #fa6400;

  --clap-font-size: 22px;

  --clap-shadow-light-orange: 0 0 10px 5px #fa640020;
  --clap-shadow-strong-orange: 0 0 20px 10px #fc4c3320;
  --clap-color-violet: rgb(106, 21, 169);
  --clap-color-violet80: rgba(106, 21, 169, 0.8);
  --clap-color-violet20: rgba(106, 21, 169, 0.2);

  --clap-text-color-primary: #666;
  --clap-text-color-secondary: #999;

  --clap-color-orange-100: rgba(255, 244, 236, 1);
  --clap-shadow-card-21: 0px 13px 16px rgba(0, 0, 0, 0.051);
}

body {
  margin: 0;
  /* font-family: Gilroy, sans-serif; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--clap-text-color-primary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
